import {useEffect, useState} from "react";
import '../App.css'

const Results = (props) => {
    const result = props.results

    const [foodPriority, setFoodPriority] = useState(result.carb === "Either" ? 50 : result.carb === "Pita" ? 100 : 0);
    const [bevsPerFood, setBevsPerFood] = useState(result.bev === "Yes" ? 1 : 0);
    const [totalGyro, setTotalGyro] = useState(0)
    const [totalRice, setTotalRice] = useState(0)
    const [totalBevs, setTotalBevs] = useState(0)

    const gyroCost = 5
    const overRiceCost = 6
    const beverageCost = 1

    const calculateAffordableSets = () => {
        // Calculate the weighted average cost of food based on the priority
        const averageFoodCost = (gyroCost * foodPriority / 100) + (overRiceCost * (100 - foodPriority) / 100);
        const combinedCost = averageFoodCost + (beverageCost * bevsPerFood);
        const totalSets = Math.floor(result.budget / combinedCost);

        // Calculate the number of sets for each type of food
        const setsGyro = Math.round(totalSets * (foodPriority / 100));
        const setsRice = totalSets - setsGyro;
        const setsBev = totalSets * bevsPerFood

        setTotalGyro(setsGyro)
        setTotalRice(setsRice)
        setTotalBevs(setsBev)
    };

    useEffect(() => {
        calculateAffordableSets()
    }, [calculateAffordableSets, props.results]);

    return (
        <div className="questionContainer fadeIn" style={{marginTop: "-4px", position: "relative"}}>
            <div className="questionText">
                You Can Afford:
                <div className={'divider'}/>
                <ul>
                    {totalGyro !== 0 ? (<li>{totalGyro + " " + result.meat + " Gyro"}</li>) : ""}
                </ul>
                <ul>
                    {totalRice !== 0 ? (<li>{totalRice + " " + result.meat + " Over Rice"}</li>) : ""}
                </ul>
                <ul>
                    {totalBevs !== 0 ? (<li>{totalBevs + " Beverages"}</li>) : ""}
                </ul>
                <div className={'divider'}/>
                Total: {totalRice + totalGyro} Orders / {totalBevs} Drinks <br/>
                Left Over Money: ${result.budget - ((totalRice * overRiceCost) + (totalGyro * gyroCost) + (beverageCost * totalBevs))}


                <div style={{marginTop: "32px", position: "absolute", bottom: 0, width: "90%"}}>
                    Adjustments:
                    <div className={'divider'}/>
                    <div>
                        <label>Want more drinks?: {bevsPerFood}</label>
                        <input
                            type="range"
                            min="0"
                            max="5"
                            value={bevsPerFood}
                            style={{width: "100%"}}
                            onChange={(e) => {
                                setBevsPerFood(parseInt(e.target.value))
                                calculateAffordableSets()
                            }}
                        />
                    </div>
                    <div>
                        <label>Over Rice vs Gyro?:</label>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            step="5"
                            value={foodPriority}
                            style={{width: "100%"}}
                            onChange={(e) => {
                                setFoodPriority(parseInt(e.target.value))
                                calculateAffordableSets()
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Results;